import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { useState } from "react";
import { useTheme } from '@mui/material';

export function ShadowBox({children}) {
	const [elevation, setElevation] = useState(0);
	const theme = useTheme();
	return (
		<Paper sx={{padding: 4}} elevation={elevation} square>
			<Slider value={elevation} onChange={(e, v) => setElevation(v)} step={1} marks min={0} max={24}/>
			<Typography variant="h6">elevation: {elevation}</Typography>
			<pre>box-shadow: {theme.shadows[elevation]}</pre>
			{children}
		</Paper>
	)
}