import { ShadowBox } from "./ShadowBox";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function App() {
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item>
          <ShadowBox>
            <Typography variant="h1">Hello World</Typography>
            <ShadowBox><Typography variant="h4">Inner Box</Typography></ShadowBox>
          </ShadowBox>
        </Grid>
        <Grid item>
          <Paper sx={{padding: 4, boxShadow: "0px 8px 48px rgba(0, 0, 0, 0.1)"}}>
            <Typography variant="h1">Main thing</Typography>
            <Paper sx={{padding: 4, margin: 4, boxShadow: "0px 16.4938px 98.963px rgba(0, 0, 0, 0.1)"}}>
              <Typography variant="h4">Inner Box</Typography>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
